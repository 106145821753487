import React from 'react';
import styled from 'styled-components';

const NextTable = styled.table`
  table-layout: fixed;
`;

const HeaderRow = styled.tr`
  font-weight: bold;
`;

const Cell = styled.td`
  padding: 10px;
`;

function toGb(size) {
  return Math.round(10 * size / (1024 * 1024 * 1024)) / 10;
}

export class Next extends React.Component {
  state = {
    showsWithDeletions: null,
  };

  async componentDidMount() {
    // need a next instead.
    // const response = await fetch('/api/deletionCandidates');
    // const {showsWithDeletions} = await response.json();
    // this.setState({showsWithDeletions});
  }

  render() {
    if (!this.state.showsWithDeletions) {
      return 'waiting...';
    }

    if (!this.state.showsWithDeletions.length) {
      return 'Nothing to Next...';
    }

    return (
      <NextTable>
        <tbody>
          <HeaderRow>
            <Cell>Show</Cell>
            <Cell>Total Size</Cell>
            <Cell>Delete Size</Cell>
            <Cell>Opportunity Size</Cell>
          </HeaderRow>

          {this.state.showsWithDeletions.map(info => {
            console.log(info);
            return (
              <tr key={info.show.tvdbId}>
                <Cell>{info.show.title}</Cell>
                <Cell>{toGb(info.show.sizeOnDisk)} Gb</Cell>
                <Cell>{toGb(info.totalSizeToDelete)} Gb</Cell>
                <Cell>{toGb(info.opportunitySize)} Gb</Cell>
              </tr>
            );
          })}
        </tbody>
      </NextTable>
    );
  }
}

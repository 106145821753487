import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {EditType} from './EditType';
import {getImagePath} from './utils';

const GlobalStyle = createGlobalStyle`
  body {
    overscroll-behavior: none;
  }
`;

const SlideContainer = styled.div`
  background-color: rgba(0, 0, 0);
`;

const Banner = styled.img`
  display: block;
  position: fixed;
  top: 0;
  width: 100vw;
`;

const ContentContainer = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  height: 100vh;
  overflow: auto;
`;

const Poster = styled.img`
  border: 5px solid white;
  height: 60vh;
  margin: 40px;
  visibility: ${({isVisible}) => (isVisible ? 'visible' : 'hidden')};

  @media (max-width: 480px) {
    display: none;
  }
`;

const PosterPlaceholder = styled.div`
  border: 5px solid transparent;
  height: 60vh;
  margin: 40px;
`;

const Content = styled.div`
  padding: 40px;
  color: white;
  max-width: 100;

  @media (max-width: 480px) {
    max-width: 100vw;
  }
`;

const ShowName = styled.a`
  color: white;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const Shoverview = styled.p`
  font-size: 18px;
`;

const VoteAverage = styled.p`
  display: inline-block;
  color: black;
  padding: 2px 8px;
  margin: 1px -8px;

  background-color: ${({avg}) => {
    if (avg >= 8) {
      return 'green';
    }

    if (avg < 5) {
      return 'red';
    }

    return 'yellow';
  }};
`;

const SearchLink = styled.a`
  display: block;
  color: inherit;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
`;

export class Slide extends React.Component {
  render() {
    const show = this.props.show;
    console.log({props: this.props});
    //         <Details show={show} />

    console.log(show);

    const overview =
      show.overview && show.overview.length > 300
        ? show.overview.substr(0, 300) + '...'
        : show.overview;

    const ratings = [
      show.plexRating,
      show.sonarrRating,
      show.traktRating,
    ].filter(Boolean);
    const avgRating = !ratings.length
      ? ''
      : Math.floor(
          ratings.reduce((total, nextRating) => total + nextRating, 0) /
            ratings.length
        );

    return (
      <SlideContainer>
        {!!show.banner && (
          <Banner src={`https://artworks.thetvdb.com/banners/${show.banner}`} />
        )}
        <GlobalStyle />
        {console.log(show)}
        <ContentContainer>
          <Content>
            <ShowName
              href={`https://www.thetvdb.com/series/${show.slug}`}
              target="_blank"
            >
              {show.name || show.slug}
            </ShowName>
            <Shoverview>{overview}</Shoverview>
            <br />
            <p>First aired: {show.firstAired.split('-')[0]}</p>
            <VoteAverage avg={avgRating}>Vote average: {avgRating}</VoteAverage>
            <SearchLink
              href={`https://www.google.com/search?q=${show.name} rotten tomatoes`
                .replace(' ', '+')
                .replace('&', '%26')}
              target="_blank"
            >
              search
            </SearchLink>
            <EditType key={show.slug} show={show} tags={this.props.tags} />
          </Content>
        </ContentContainer>
      </SlideContainer>
    );
  }
}

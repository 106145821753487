import React, {useEffect, useRef} from 'react';
import styled, {keyframes} from 'styled-components';
import {ShowType} from './ShowType';

const ControlWrapper = styled.div`
  display: flex;
  font-size: 1.5em;
  justify-content: space-between;
`;

const Dialog = styled.dialog`
  /* noop */
`;

const HeaderCell = styled.th`
  font-weight: 600;
  padding: 0 10px;
`;

const Cell = styled.td`
  padding: 4px 10px;
`;

const Input = styled.input`
  display: inline-block;
  margin-top: 2px;
  vertical-align: middle;
`;

export const Filter = ({
  isOpen,
  onClose,
  onOpen,
  tags,
  onIncludeChange,
  onExcludeChange,
  excludeFilters,
  includeFilters,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (isOpen) {
      ref.current.showModal();
    } else {
      ref.current.close();
    }
  }, [ref, isOpen]);

  const onClick = (e) => {
    if (e.target.tagName === 'DIALOG') {
      onClose();
    }
  };

  tags = ['empty', ...tags];

  return (
    <div>
      <span onClick={onOpen}>Filter</span>
      <Dialog ref={ref} onClick={onClick}>
        <table>
          <tbody>
            <tr>
              <HeaderCell>Include</HeaderCell>
              <HeaderCell>Exclude</HeaderCell>
            </tr>
            {tags.map((tag) => {
              const slug = tag.replace(/ /g, '_');

              return (
                <tr key={tag}>
                  <Cell>
                    <label htmlFor={`include-${slug}`}>
                      <Input
                        checked={includeFilters.indexOf(tag) > -1}
                        id={`include-${slug}`}
                        type="checkbox"
                        onChange={(e) => {
                          onIncludeChange(tag, e.target.checked);
                        }}
                      />
                      {tag}
                    </label>
                  </Cell>
                  <Cell>
                    <label htmlFor={`exclude-${slug}`}>
                      <Input
                        checked={excludeFilters.indexOf(tag) > -1}
                        id={`exclude-${slug}`}
                        type="checkbox"
                        onChange={(e) => {
                          onExcludeChange(tag, e.target.checked);
                        }}
                      />
                      {tag}
                    </label>
                  </Cell>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dialog>
    </div>
  );
};

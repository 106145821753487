import React from 'react';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  position: relative;
`;

const Select = styled.select`
  appearance: none;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;

export class ShowType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {type: props.show.type};
  }

  onChange = async (e) => {
    const type = e.target.value;

    await fetch('/api/edit', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.props.show.tvdbId,
        value: {type},
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setState({type});
  };

  render() {
    const options = [
      'None',
      'A-List',
      'B-List',
      'Excited',
      'Vet',
      'Invest',
      'Passive',
      'Finish',
    ];

    return (
      <SelectWrapper>
        {this.state.type || 'None'}
        <Select onChange={this.onChange} value={this.state.type || 'None'}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </SelectWrapper>
    );
  }
}

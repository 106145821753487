import React from 'react';
import styled from 'styled-components';

import {toGb} from './utils';
import {Slide} from './Slide';

const cache = {};

const Deets = styled.div`
  position: absolute;
  font-size: 18px;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
`;

export class Delete extends React.Component {
  state = {
    showsWithDeletions: null,
    currentShowIndex: -1,
    showData: null,
  };

  deleteAction = async action => {
    await fetch('/api/delete', {
      method: 'POST',
      body: JSON.stringify({
        seriesId: this.state.showsWithDeletions[this.state.currentShowIndex].show.id,
        action,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.nextSlide();
  };

  nextSlide = () => {
    this.setState({
      currentShowIndex: Math.min(
        this.state.showsWithDeletions.length - 1,
        this.state.currentShowIndex + 1,
      ),
    });
  };

  prevSlide = () => {
    this.setState({
      currentShowIndex: Math.max(0, this.state.currentShowIndex - 1),
    });
  };

  onKeyDown = e => {
    switch (e.key) {
      case 'ArrowRight':
        this.nextSlide();
        break;
      case 'ArrowLeft':
        this.prevSlide();
        break;
      case 'Backspace':
        if (
          !window.confirm(
            `Delete all episodes of ${
              this.state.showsWithDeletions[this.state.currentShowIndex].show.name
            }? Click Cancel.`,
          )
        ) {
          this.deleteAction('deleteSeries');
        } else {
          window.alert('nevermind');
        }
        break;
      case 'd':
        this.deleteAction('deleteEpisodes');
        break;
      case 'k':
        this.deleteAction('keep');
        break;
      default:
        console.log(e.key);
        break;
    }
  };

  async componentDidMount() {
    const response = await fetch('/api/deletionCandidates');
    const {showsWithDeletions} = await response.json();

    window.addEventListener('keydown', this.onKeyDown);

    this.setState({showsWithDeletions, currentShowIndex: 0});
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentShowIndex === this.state.currentShowIndex) {
      return;
    }
    this.setState({showData: null});

    if (!this.state.showsWithDeletions.length) {
      return;
    }

    const info = this.state.showsWithDeletions[this.state.currentShowIndex];
    const tvdbId = info.show.tvdbId;

    if (!cache[tvdbId]) {
      const response = await fetch(`/api/show/${tvdbId}`);
      const data = await response.json();

      cache[tvdbId] = data[tvdbId];
    }

    this.setState({showData: cache[tvdbId]});
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    console.log({state: this.state});

    if (!this.state.showsWithDeletions) {
      return 'wait for it...';
    }

    if (!this.state.showsWithDeletions.length) {
      return 'no shows to delete';
    }

    const info = this.state.showsWithDeletions[this.state.currentShowIndex];

    const epi = info.episodesToDelete.length === 1 ? 'episode' : 'episodes';
    return (
      <div>
        {!!this.state.showData && <Slide show={this.state.showData} />}

        <Deets>
          {this.state.currentShowIndex + 1} of {this.state.showsWithDeletions.length} -{' '}
          {info.episodesToDelete.length} {epi} - {toGb(info.totalSizeToDelete)}gb of{' '}
          {toGb(info.show.sizeOnDisk)}gb
        </Deets>
      </div>
    );
  }
}

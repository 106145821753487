import React from 'react';
import styled from 'styled-components';

const Page = styled.article`
  margin: 20px;
`;

const Search = styled.input`
  border: 1px solid black;
  display: block;
  font-size: 32px;
  margin: auto;
  width: 80%;
`;

const Paragraph = styled.p`
  overflow: auto;
  max-height: 100%;
`;

const Item = styled.li`
  align-items: center;
  display: flex;
  height: 200px;
  position: relative;
  margin: 10px;
  padding: 10px;

  & + & {
    border-top: 1px solid black;
  }
`;

const Poster = styled.img`
  height: auto;
  max-width: 20vw
  margin-right: 20px;
`;

const ActionButton = styled.button`
  display: block;
  margin-left: 20px;
  min-width: 100px;
  height: 3em;
`;

export class ItemWrapper extends React.Component {
  addExisting = async () => {
    const backfill = window.confirm(`Do you want to backfill ${this.props.show.seriesName}?`);

    const resp = await fetch('/api/addSeries', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.props.show.id,
        backfill,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await resp.json();

    window.alert(data.ok || data.error);
  };

  addNew = async () => {
    const backfill = window.confirm(`Do you want to backfill ${this.props.show.seriesName}?`);

    const resp = await fetch('/api/addNewSeries', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.props.show.id,
        backfill,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await resp.json();

    window.alert(data.ok || data.error);
  };

  render() {
    const {dbShow, show} = this.props;
    const {sonarrId} = dbShow || {};
    let actionButton;

    if (sonarrId) {
      actionButton = <ActionButton disabled={true}>Exists</ActionButton>;
    } else if (dbShow) {
      actionButton = <ActionButton onClick={this.addExisting}>Add to sonarr</ActionButton>;
    } else {
      actionButton = <ActionButton onClick={this.addNew}>Add</ActionButton>;
    }

    return (
      <Item key={show.id}>
        {console.log(show)}
        {!!show.banner && <Poster src={`https://artworks.thetvdb.com${show.banner}`} />}

        <Paragraph style={{flexGrow: 1}}>
          <a
            href={`https://www.thetvdb.com/series/${show.slug}`}
            style={{fontWeight: 'bold'}}
            target="_blank"
          >
            {show.seriesName} - ({show.firstAired || 'No air date listed'})
          </a>
          <br />
          {show.overview || 'No overview set'}
        </Paragraph>

        {actionButton}
      </Item>
    );
  }
}

export class Add extends React.Component {
  state = {tvdbResult: [], existingShows: []};

  onSubmit = async e => {
    e.preventDefault();
    this.setState({tvdbResult: [], existingShows: []});

    const response = await fetch('/api/search', {
      method: 'POST',
      body: JSON.stringify({
        q: e.target[0].value,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const {tvdbResult, existingShows} = await response.json();
    this.setState({tvdbResult, existingShows});
  };

  render() {
    const existingById = this.state.existingShows.reduce((shows, nextShow) => {
      shows[nextShow.tvdbId] = nextShow;
      return shows;
    }, {});

    return (
      <Page>
        <form onSubmit={this.onSubmit}>
          <Search />
        </form>
        <ul>
          {this.state.tvdbResult
            .slice(0, 10)
            .map(show => <ItemWrapper dbShow={existingById[String(show.id)]} show={show} />)}
        </ul>
      </Page>
    );
  }
}

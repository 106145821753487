import React from 'react';
import styled, {keyframes} from 'styled-components';
import {ShowType} from './ShowType';

// THIS IS SO JANKY. MAKE THE REAL CODE MORE SHARABLE YOU LAZY ASS.

const ControlWrapper = styled.div`
  // display: flex;
  font-size: 1.5em;
  // justify-content: space-between;
`;

const Dialog = styled.dialog`
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  /* background-color: red;
  height: 50px;
  width: 50px; */

  /* &::backdrop {
    background-color: blue;
  } */
`;

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const Cell = styled.td`
  // padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    text-align: right;
  }
`;

const HeaderCell = styled.th`
  font-weight: 600;
  padding: 4px;
`;

const Center = styled.div`
  text-align: center;
`;

const Link = styled.a`
  & + & {
    border-left: 1px solid black;
    margin-left: 1em;
    padding-left: 1em;
  }
`;

const ItemWrapper = styled.label`
  display: block;
  cursor: pointer;
  margin: 5px 0;
  position: relative;
`;

const ItemText = styled.span`
  color: red;
  cursor: pointer;

  &.added {
    color: green;
  }

  &::after {
    content: '×';
    padding: 0 3px 0 10px;
    position: relative;
    top: 1px;
  }
`;

const Action = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;

const HiddenSelect = styled.select`
  appearance: none;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const EditInput = styled.input`
  max-width: 100%;
`;

const ItemCheckbox = styled.input`
  display: none;

  &:checked + ${ItemText}:not(.added) {
    color: inherit;
  }
`;

const Button = styled.button`
  appearance: none;
  background: white;
  font-family: inherit;
  font-size: inherit;
  color: black;
  text-transform: uppercase;
  border: none;
  padding: 10px;
  display: block;
  width: 100%;
  margin-top: 20px;
`;

class Item extends React.Component {
  onChange = (e) => {
    this.props.onChange(e.target.checked);
  };

  render() {
    return (
      <ItemWrapper>
        <ItemCheckbox
          checked={this.props.checked}
          onChange={this.onChange}
          type="checkbox"
        />
        <ItemText className={this.props.isAdded ? 'added' : null}>
          {this.props.children}
        </ItemText>
      </ItemWrapper>
    );
  }
}

const FIELDS = [
  {fieldName: 'shouldTrack', type: 'bool'},
  {fieldName: 'type', type: 'type'},
  {fieldName: 'tags', type: 'list', options: (props) => props.tags},
];

class List extends React.Component {
  constructor(props) {
    super(props);
    const {fieldName, show} = this.props;

    const items = show[fieldName] || [];

    this.state = {showOptions: false, items, selected: [...items]};
  }

  onAdd = (e) => {
    const newSelected = [...this.state.selected, e.target.value];
    newSelected.sort();

    this.setNewSelected(newSelected);
  };

  setNewSelected = async (newSelected) => {
    console.log('on change');
    if (!this.hasDiff(newSelected)) {
      console.log('has no diff');
      return;
    }
    console.log('has a diff');

    await fetch('/api/edit', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.props.show.tvdbId,
        value: {[this.props.fieldName]: newSelected},
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setState({
      selected: newSelected,
      items: [...newSelected],
    });
  };

  onChange = async (item, checked) => {
    let selected;
    if (checked) {
      selected = [...this.state.selected, item];
    } else {
      selected = this.state.selected.filter((x) => x !== item);
    }

    this.setNewSelected(selected);
  };

  hasDiff = (selected) => {
    const {items} = this.state;

    if (items.length !== selected.length) {
      return true;
    }

    return selected.some((x) => items.indexOf(x) === -1);
  };

  showOptions = () => {
    this.setState({showOptions: true});
  };

  render() {
    const items = [...new Set([...this.state.selected, ...this.state.items])];
    items.sort();

    return (
      <>
        <ControlWrapper>
          <Action>
            +
            <HiddenSelect
              onChange={this.onAdd}
              name={'add' + this.props.fieldName}
              value=""
            >
              <option value="" />
              {this.props.options
                .filter((option) => this.state.selected.indexOf(option) === -1)
                .map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </HiddenSelect>
          </Action>
          {/* {!!this.hasDiff() && <Action onClick={this.onDone}>✓</Action>} */}
        </ControlWrapper>
        {items.map((item) => (
          <Item
            key={item}
            isAdded={this.state.items.indexOf(item) === -1}
            checked={this.state.selected.indexOf(item) > -1}
            onChange={(checked) => this.onChange(item, checked)}
          >
            {item}
          </Item>
        ))}
      </>
    );
  }
}

class Number extends React.Component {
  constructor(props) {
    super(props);
    const {fieldName, show} = this.props;

    this.state = {num: show[fieldName]};
  }

  onChange = async (e) => {
    const num = e.target.value;

    const {fieldName} = this.props;

    const coercedNumber = parseFloat(num, 10);

    if (!isNaN(coercedNumber)) {
      await fetch('/api/edit', {
        method: 'POST',
        body: JSON.stringify({
          tvdbId: this.props.show.tvdbId,
          value: {[fieldName]: coercedNumber},
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    this.setState({num});
  };

  render() {
    const {fieldName, show} = this.props;

    return <EditInput value={this.state.num} onChange={this.onChange} />;
  }
}

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    const {fieldName, show} = this.props;

    this.state = {checked: show[fieldName]};
  }

  onChange = async (e) => {
    const checked = e.target.checked;
    const {fieldName} = this.props;

    await fetch('/api/edit', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.props.show.tvdbId,
        value: {[fieldName]: checked},
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setState({checked});
  };

  render() {
    const {fieldName, show} = this.props;

    return (
      <input
        checked={this.state.checked}
        onChange={this.onChange}
        type="checkbox"
      />
    );
  }
}

export class EditType extends React.Component {
  add = async (tvdbId, backfill) => {
    const resp = await fetch('/api/addSeries', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId,
        backfill,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await resp.json();

    if (data.error === 'BAD_TVDB_ID') {
      return;
    }

    window.alert(data.ok || data.error);
  };

  onAddButton = async () => {
    const backfill = window.confirm(
      `Do you want to backfill ${this.props.show.name}?`
    );
    this.add(this.props.show.tvdbId, backfill);
  };

  onDelete = async () => {
    if (window.confirm(`Do you really want delete ${this.props.show.name}?`)) {
      await fetch('/api/delete', {
        method: 'POST',
        body: JSON.stringify({
          seriesId: this.props.show.sonarrId,
          action: 'deleteSeries',
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      window.alert('Boom - deleted');
      this.props.close();
    }
  };

  renderField = (fieldName, type, options) => {
    const {show, tags} = this.props;

    switch (type) {
      case 'bool':
        return <Checkbox fieldName={fieldName} show={show} />;
      case 'type':
        return <ShowType show={show} />;
      case 'number':
        return <Number fieldName={fieldName} show={show} />;
      case 'list':
        return <List fieldName={fieldName} show={show} options={options} />;
      default:
        throw new Error(`Have not defined field type: {type}`);
    }
  };

  render() {
    const {show, tags} = this.props;
    console.log({tags});

    const urls = [
      {
        href: `https://www.thetvdb.com/series/${show.slug}`,
        text: 'tvdb',
        condition: !!show.slug,
      },
      {
        href: `/sonarr/${show.slug}`,
        text: 'sonarr',
        condition: !!show.sonarrId,
      },
      {
        href: `https://www.imdb.com/title/${show.imdbId}`,
        text: 'imdb',
        condition: !!show.imdbId,
      },
      {
        href: `https://app.plex.tv/desktop/#!/server/e823b06ee74a4f5915b2b5920bd27f2bb88e8a3c/details?key=%2Flibrary%2Fmetadata%2F${show.plexId}`,
        text: 'plex',
        condition: !!show.plexId,
      },
    ].map(({condition, href, text}) => {
      const Comp = condition ? 'a' : 'span';

      return (
        <Link key={text}>
          <Comp href={href} rel="noopener noreferrer" target="_blank">
            {text}
          </Comp>
        </Link>
      );
    });

    return (
      <div>
        <Table>
          <tbody>
            {FIELDS.map(({fieldName, type, options = () => {}}) => (
              <tr key={fieldName}>
                <Cell>{fieldName}</Cell>
                <Cell>
                  {this.renderField(fieldName, type, options(this.props))}
                </Cell>
              </tr>
            ))}
          </tbody>
        </Table>
        {show.sonarrId ? (
          <Button id="show-delete" onClick={this.onDelete}>
            Delete
          </Button>
        ) : (
          <Button id="show-add" onClick={this.onAddButton}>
            Add
          </Button>
        )}
      </div>
    );
  }
}

import React from 'react';
import Swipe from 'react-easy-swipe';

import {Slide} from './Slide';

export class New extends React.Component {
  state = {
    shows: null,
    stats: null,
    currentShowIndex: 0,
  };

  constructor(props) {
    super(props);

    this.yStart = null;
  }

  onSwipeEnd = (e) => {
    this.ref.style.transform = null;

    if (Math.abs(this.y) > 100) {
      if (this.y > 0) {
        // console.log('DO NOT TRACK');
        this.doNotTrackIt();
      } else {
        // console.log('TRACK');
        this.trackIt();
      }
    } else if (Math.abs(this.x) > 100) {
      if (this.x > 0) {
        // console.log('PREV');
        this.prevSlide();
      } else {
        // console.log('NEXT');
        this.nextSlide();
      }
    }
  };

  onSwipeMove = (e) => {
    this.y = e.y;
    this.x = e.x;

    if (Math.abs(this.y) > 20) {
      this.ref.style.transform = `translateY(${this.y}px)`;
    } else if (Math.abs(this.x) > 20) {
      this.ref.style.transform = `translateX(${this.x}px)`;
    }
  };

  onSwipeStart = (e) => {
    this.y = null;
    this.x = null;
  };

  shouldTrack = (track) => {
    return fetch('/api/decide', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.state.shows[this.state.currentShowIndex].tvdbId,
        decision: track,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  nextSlide = (x, e) => {
    console.log('next');
    e && e.preventDefault();
    console.log(
      'next next',
      this.state.shows.length - 1,
      this.state.currentShowIndex + 1
    );

    if (this.state.shows.length === this.state.currentShowIndex + 1) {
      window.location.href = '/';
    }

    this.setState({
      currentShowIndex: Math.min(
        this.state.shows.length - 1,
        this.state.currentShowIndex + 1
      ),
    });
  };

  prevSlide = (x, e) => {
    e && e.preventDefault();
    this.setState({
      currentShowIndex: Math.max(0, this.state.currentShowIndex - 1),
    });
  };

  trackIt = async (x, e) => {
    e && e.preventDefault();
    await this.shouldTrack(true);
    this.nextSlide();
  };

  doNotTrackIt = async (x, e) => {
    e && e.preventDefault();
    await this.shouldTrack(false);
    this.nextSlide();
  };

  onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowRight':
        this.nextSlide();
        break;
      case 'ArrowLeft':
        this.prevSlide();
        break;
      case 'ArrowUp':
        this.trackIt();
        break;
      case 'ArrowDown':
        this.doNotTrackIt();
        break;
      default:
        break;
    }
  };

  async componentDidMount() {
    const response = await fetch('/api/undecided');
    const {shows} = await response.json();

    window.addEventListener('keydown', this.onKeyDown);

    const statsResponse = await fetch('/api/stats');
    const {stats} = await statsResponse.json();

    this.setState({shows, stats});
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    console.log(this.state.shows);
    if (!this.state.shows) {
      return 'wait for it...';
    }

    if (!this.state.shows.length) {
      return 'nothing to review';
    }

    console.log(this.state.currentShowIndex);
    const show = this.state.shows[this.state.currentShowIndex];

    return (
      <Swipe
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}
        // onSwipeDown={this.doNotTrackIt}
        // onSwipeLeft={this.nextSlide}
        // onSwipeRight={this.prevSlide}
        // onSwipeUp={this.trackIt}
      >
        <div ref={(ref) => (this.ref = ref)}>
          <Slide show={show} tags={this.state.stats.tags} />
        </div>
      </Swipe>
    );
  }
}

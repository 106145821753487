import React from 'react';
import styled, {keyframes} from 'styled-components';
import {toGb} from './utils';
import {Edit} from './Edit';
import {ShowType} from './ShowType';
import {Filter} from './Filter';
import axios from 'axios';

const Table = styled.table`
  /* table-layout: fixed; */
  max-width: 100vw;
  width: 100%;
`;

const HeaderRow = styled.tr`
  font-weight: bold;
`;

const Row = styled.tr`
  :nth-child(2n) {
    background-color: #f5f5f5;
  }

  :hover {
    background-color: lightblue;
  }
`;

const Cell = styled.td`
  /* background-color: rgba(0, 0, 0, 0.7); */
  padding: 2px;
`;

const X = styled.div`
  ::before {
    color: red;
    content: '✗';
  }
`;

const Check = styled.div`
  ::before {
    color: green;
    content: '✓';
  }
`;

const BUTTON_WIDTH = '50px';
const BUTTON_HEIGHT = '2em';

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Stats = styled.div`
  margin: 12px;
  text-align: center;
`;

const pulsate = keyframes`
0% {
  transform: scale(0.1);
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  transform: scale(1.2);
  opacity: 0;
}
`;

const LOADER_SIZE = 50;

const Loader = styled.div`
  border: 5px solid #333;
  border-radius: ${LOADER_SIZE}px;
  height: ${LOADER_SIZE}px;
  opacity: 0;
  width: ${LOADER_SIZE}px;

  animation: ${pulsate} 1s ease-out;
  animation-iteration-count: infinite;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

function getAvgRating(show) {
  const ratings = [
    show.imdbRating,
    show.plexRating,
    show.sonarrRating,
    show.traktRating,
  ].filter(Boolean);
  return !ratings.length
    ? ''
    : (
        ratings.reduce((total, nextRating) => total + nextRating, 0) /
        ratings.length
      ).toFixed(1);
}

class ShowRow extends React.Component {
  onTitleClick = () => {
    this.props.onTitleClick(this.props.show.tvdbId);
  };

  render() {
    const show = this.props.show;

    const size = this.props.showSize && (
      <Cell>{show.sizeOnDisk ? `${toGb(show.sizeOnDisk)}gb` : 'N/A'}</Cell>
    );

    const showType = this.props.showType && (
      <Cell>
        <ShowType show={show} />
      </Cell>
    );

    const left = !show.plexLeafCount
      ? ''
      : show.plexLeafCount - show.plexViewedLeafCount;

    const avgRating = getAvgRating(show);

    return (
      <Row>
        <Cell>{show.oIndex}</Cell>
        <Cell>
          <a onClick={this.onTitleClick}>{show.name}</a>
        </Cell>
        <Cell>{left}</Cell>
        <Cell>{avgRating}</Cell>
        {size}
        {showType}
      </Row>
    );
  }
}

export class All extends React.Component {
  static defaultProps = {
    showSize: false,
    showType: false,
  };

  state = {
    includeFilters: [],
    excludeFilters: ['jen'],
    isFilterOpen: false,
    filter: '',
    editId: 136,
    // editId: 0,
    error: false,
    stats: null,
    shows: null,
    hide: [],
    sort: 'score',
    sortDir: -1,
  };

  componentDidMount() {
    this.getAll();
    this.getStats();
  }

  getAll = async () => {
    const response = await fetch('/api/all', {
      headers: {
        Accept: 'application/json',
      },
    });

    if (response.status === 200) {
      const {shows} = await response.json();
      this.setState({shows});
    } else {
      this.setState({error: true});
    }
  };

  getStats = async () => {
    const response = await fetch('/api/stats', {
      headers: {
        Accept: 'application/json',
      },
    });
    const {stats} = await response.json();
    this.setState({stats});
  };

  hide = (id) => {
    this.setState({hide: [...this.state.hide, id]});
  };

  onClose = () => {
    this.setState({editId: null});
  };

  onTitleClick = (tvdbId) => {
    this.setState({editId: tvdbId});
  };

  updateFilter = (e) => {
    this.setState({filter: e.target.value});
  };

  onFilterOpen = () => {
    this.setState({isFilterOpen: true});
  };

  onFilterClose = () => {
    this.setState({isFilterOpen: false});
  };

  onIncludeChange = (tag, checked) => {
    const {includeFilters} = this.state;
    if (checked) {
      return this.setState({includeFilters: [...includeFilters, tag]});
    } else {
      return this.setState({
        includeFilters: includeFilters.filter((t) => t !== tag),
      });
    }
  };

  onExcludeChange = (tag, checked) => {
    const {excludeFilters} = this.state;
    if (checked) {
      return this.setState({excludeFilters: [...excludeFilters, tag]});
    } else {
      return this.setState({
        excludeFilters: excludeFilters.filter((t) => t !== tag),
      });
    }
  };

  renderStats() {
    if (!this.state.stats) {
      return;
    }

    const ShowComp = this.state.stats.showsCount ? 'a' : 'span';
    const DeleteComp = this.state.stats.deleteCount ? 'a' : 'span';

    return (
      <Stats>
        <ShowComp href="/new">
          {this.state.stats.showsCount}&nbsp;review
        </ShowComp>{' '}
        |{' '}
        <DeleteComp href="/delete">
          {this.state.stats.deleteCount}&nbsp;delete
        </DeleteComp>{' '}
        | <a href="/add">Add</a>
      </Stats>
    );
  }

  nameFilter = (show) => {
    return (
      show.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1
    );
  };

  standardFilter = (show) => {
    if (this.state.hide.indexOf(show.tvdbId) > -1) {
      return false;
    }

    const tags = show.tags || [];
    const {includeFilters, excludeFilters} = this.state;

    if (includeFilters.indexOf('empty') > -1) {
      return !tags.length;
    }

    if (excludeFilters.indexOf('empty') > -1 && !tags.length) {
      return false;
    }

    if (!includeFilters.every((includeTag) => tags.indexOf(includeTag) > -1)) {
      return false;
    }

    if (excludeFilters.some((excludeTag) => tags.indexOf(excludeTag) > -1)) {
      return false;
    }

    if (show.plexLeafCount <= show.plexViewedLeafCount) {
      // if (show.jen) {
      //   return false;
      // }
      //
      return false;
    }

    return !show.sonarrId || show.sizeOnDisk;
  };

  sortBy = (newSort) => {
    this.setState({
      sort: newSort,
      sortDir: newSort === this.state.sort ? this.state.sortDir * -1 : 1,
    });
  };

  render() {
    if (this.state.error) {
      return <LoaderWrapper>Welp, that didn't work</LoaderWrapper>;
    }

    if (!this.state.shows) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    // this.state.shows.sort((a, b) => {
    //   const aSize = a.sizeOnDisk || 0;
    //   const bSize = b.sizeOnDisk || 0;
    //
    //   return bSize - aSize;
    // });

    const shows = this.state.shows.filter(
      !this.state.filter ? this.standardFilter : this.nameFilter
    );

    shows.sort((a, b) => {
      let aVal = a[this.state.sort];
      let bVal = b[this.state.sort];
      if (this.state.sort === 'remaining') {
        aVal = (a.plexLeafCount || 0) - (a.plexViewedLeafCount || 0);
        bVal = (b.plexLeafCount || 0) - (b.plexViewedLeafCount || 0);
      } else if (this.state.sort === 'avg') {
        aVal = getAvgRating(a);
        bVal = getAvgRating(b);
      }

      if (typeof aVal === 'string') {
        aVal = aVal.toLowerCase();
      }

      if (typeof bVal === 'string') {
        bVal = bVal.toLowerCase();
      }

      if (aVal === bVal) {
        aVal = a.name;
        bVal = b.name;
      }

      if (aVal && bVal) {
        return (aVal > bVal ? 1 : -1) * this.state.sortDir;
      }

      if (aVal) {
        return 1 * this.state.sortDir;
      }

      return -1 * this.state.sortDir;
    });

    const sonarrCount = shows.reduce((total, next) => {
      return !next.sonarrId ? total + 1 : total;
    }, 0);

    const size = this.props.showSize && (
      <Cell onClick={() => this.sortBy('size')}>Size</Cell>
    );

    const showType = this.props.showType && (
      <Cell onClick={() => this.sortBy('type')}>Type</Cell>
    );

    const editShow = this.state.shows.find(
      ({tvdbId}) => tvdbId === this.state.editId
    );

    if (this.state.sort === 'score' && !this.state.filter) {
      shows.forEach((show, i) => {
        show.oIndex = i + 1;
      });
    }

    return (
      <div style={{fontSize: 12, padding: 4}}>
        {!!editShow && (
          <Edit
            show={editShow}
            close={this.onClose}
            tags={this.state.stats.tags}
          />
        )}
        <Header>
          {this.renderStats()}
          <div>
            <Filter
              onOpen={this.onFilterOpen}
              onClose={this.onFilterClose}
              isOpen={this.state.isFilterOpen}
              tags={this.state.stats.tags}
              onExcludeChange={this.onExcludeChange}
              onIncludeChange={this.onIncludeChange}
              excludeFilters={this.state.excludeFilters}
              includeFilters={this.state.includeFilters}
            />
            <input placeholder="Filter" onChange={this.updateFilter} />
          </div>
        </Header>
        <Table>
          <tbody>
            <HeaderRow>
              <Cell onClick={() => this.sortBy('score')} />
              <Cell onClick={() => this.sortBy('name')}>
                Show ({shows.length})
              </Cell>
              <Cell onClick={() => this.sortBy('remaining')}>Left</Cell>
              <Cell onClick={() => this.sortBy('avg')}>Avg</Cell>
              {size}
              {showType}
            </HeaderRow>

            {shows.map((show, i) => (
              <ShowRow
                onTitleClick={this.onTitleClick}
                hide={this.hide}
                index={i}
                key={show.tvdbId}
                show={show}
                showSize={this.props.showSize}
                showType={this.props.showType}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

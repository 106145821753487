import React from 'react';
import styled from 'styled-components';

import {Add} from './Add';
import {All} from './All';
import {Delete} from './Delete';
import {Next} from './Next';
import {New} from './New';
import {Type} from './Type';

const AppContainer = styled.div`
  overscroll-behavior: none;
  font-family: system-ui;
  line-height: 1.4;
`;

const urlMap = {
  '/': All,
  '/add': Add,
  '/delete': Delete,
  '/new': New,
  '/next': Next,
  '/type': Type,
};

const Default = () => `No route for ${window.location.pathname}`;

class App extends React.Component {
  render() {
    const Component = urlMap[window.location.pathname] || Default;
    return (
      <>
        <AppContainer>
          <Component />
        </AppContainer>
      </>
    );
  }
}

export default App;

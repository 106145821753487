import React from 'react';
import styled from 'styled-components';

import {Slide} from './Slide';

const Context = styled.div`
  position: absolute;
  font-size: 18px;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
`;

export class Type extends React.Component {
  state = {
    shows: null,
    currentShowIndex: 0,
  };

  setType = type => {
    fetch('/api/edit', {
      method: 'POST',
      body: JSON.stringify({
        tvdbId: this.state.shows[this.state.currentShowIndex].tvdbId,
        value: {type},
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  nextSlide = () => {
    this.setState({
      currentShowIndex: Math.min(this.state.shows.length - 1, this.state.currentShowIndex + 1),
    });
  };

  prevSlide = () => {
    this.setState({
      currentShowIndex: Math.max(0, this.state.currentShowIndex - 1),
    });
  };

  onKeyDown = e => {
    switch (e.key) {
      case 'ArrowRight':
        this.nextSlide();
        break;
      case 'ArrowLeft':
        this.prevSlide();
        break;
      case 'a':
        this.setType('A-List');
        this.nextSlide();
        break;
      case 'b':
        this.setType('B-List');
        this.nextSlide();
        break;
      case 'e':
        this.setType('Excited');
        this.nextSlide();
        break;
      case 'v':
        this.setType('Vet');
        this.nextSlide();
        break;
      case 'i':
        this.setType('Invest');
        this.nextSlide();
        break;
      case 'p':
        this.setType('Passive');
        this.nextSlide();
        break;
      case 'f':
        this.setType('Finish');
        this.nextSlide();
        break;
      default:
        console.log(e.key);
        break;
    }
  };

  async componentDidMount() {
    const response = await fetch('/api/untyped');
    const {shows} = await response.json();

    window.addEventListener('keydown', this.onKeyDown);

    this.setState({shows});
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    if (!this.state.shows) {
      return 'wait for it...';
    }

    if (!this.state.shows.length) {
      return 'no untyped shows left';
    }

    const show = this.state.shows[this.state.currentShowIndex];

    return (
      <React.Fragment>
        <Context>
          {this.state.currentShowIndex + 1} of {this.state.shows.length}
        </Context>
        <Slide show={show} />
      </React.Fragment>
    );
  }
}
